import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, Transition as _Transition, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
import _imports_0 from '../assets/app-download-btn.png';
const _withScopeId = n => (_pushScopeId("data-v-132d6e57"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  key: 0,
  class: "list1"
};
const _hoisted_2 = ["src", "onClick"];
const _hoisted_3 = ["src", "onClick"];
const _hoisted_4 = {
  key: 2,
  class: "list4"
};
const _hoisted_5 = ["src", "onClick"];
const _hoisted_6 = {
  class: "pop-content"
};
const _hoisted_7 = {
  class: "list5-div"
};
const _hoisted_8 = {
  class: "img-div"
};
const _hoisted_9 = ["onClick"];
const _hoisted_10 = ["src", "onClick"];
const _hoisted_11 = {
  key: 3,
  class: "list6"
};
const _hoisted_12 = {
  class: "img-div"
};
const _hoisted_13 = ["src", "onClick"];
const _hoisted_14 = {
  class: "title"
};
const _hoisted_15 = {
  key: 4,
  class: "notice-div"
};
const _hoisted_16 = ["onClick"];
const _hoisted_17 = {
  class: "img-div"
};
const _hoisted_18 = {
  class: "title"
};
const _hoisted_19 = {
  key: 6,
  class: "ads-text"
};
const _hoisted_20 = {
  key: 7,
  class: "list12"
};
const _hoisted_21 = {
  key: 8,
  class: "list13"
};
const _hoisted_22 = {
  key: 0,
  class: "list14"
};
const _hoisted_23 = ["src", "onClick"];
const _hoisted_24 = {
  key: 0,
  class: "list15"
};
const _hoisted_25 = {
  class: "right-div"
};
const _hoisted_26 = ["src", "onClick"];
const _hoisted_27 = {
  key: 11,
  class: "list16"
};
const _hoisted_28 = {
  class: "item-title"
};
const _hoisted_29 = {
  class: "item-desc"
};
const _hoisted_30 = {
  key: 12,
  class: "list20"
};
const _hoisted_31 = {
  key: 13,
  class: "list21"
};
const _hoisted_32 = {
  class: "item-right"
};
const _hoisted_33 = {
  class: "item-title"
};
const _hoisted_34 = {
  class: "item-desc"
};
const _hoisted_35 = {
  class: "right-down"
};
const _hoisted_36 = ["onClick"];
import { ref, computed, watch } from 'vue';
import { useIndexStore } from "../store";
import { storeToRefs } from 'pinia';
import { goToTheRandomAddress, isDev } from "@/utils";
import api from "../api";
export default {
  __name: 'AdsPictures',
  props: {
    list: {
      default() {
        return [];
      }
    },
    type: {
      default: 1
    }
  },
  emits: ['close', 'onload'],
  setup(__props, {
    emit: emits
  }) {
    const props = __props;
    const userIndex = useIndexStore();
    const {
      allAdsList
    } = storeToRefs(userIndex);
    const swiper = ref();
    const seconds = ref(5 * 1000);
    const value = ref(5);
    const adsList = computed(() => {
      let list = allAdsList.value.filter(v => v.class_id == props.type);
      // 调试
      if (isDev() && props.type === 21) {
        return allAdsList.value.filter(v => v.class_id == 1);
      }
      if (props.type === 6 && list.length > 10) {
        list = list.splice(0, 10);
      }
      return list;
    });
    const clickImg = item => {
      goToTheRandomAddress(item.url.split(','));
      if (item.url) {
        reportAds();
      }
    };

    // 首页弹窗广告 默认展示第一个 关闭当前展示下一个 当前为最后一个关闭弹窗
    const popAd = ref({
      show: false,
      showIndex: 0,
      list: computed(() => {
        const filterList = allAdsList.value.filter(v => v.class_id == 5);
        return filterList;
      }),
      close: index => {
        popAd.value.showIndex = index + 1;
        if (index === popAd.value.list.length - 1) {
          popAd.value.show = false;
          emits('onload');
        }
      }
    });
    const reportAds = async () => {
      await api.reportAds({
        ad_ids: props.type,
        report_type: 1
      });
    };
    watch(() => popAd.value.list, v => {
      if (props.type === 5) {
        if (sessionStorage.getItem('popAd')) return;
        if (v.length > 0) {
          popAd.value.show = true;
        } else {
          emits('onload');
        }
        sessionStorage.setItem('popAd', 0);
      }
    });
    const show14 = ref(true);
    const isResize = ref(false);
    const change = () => {
      if (!isResize.value) {
        swiper.value?.resize();
        isResize.value = true;
      }
    };
    return (_ctx, _cache) => {
      const _component_van_swipe_item = _resolveComponent("van-swipe-item");
      const _component_van_swipe = _resolveComponent("van-swipe");
      const _component_van_icon = _resolveComponent("van-icon");
      const _component_van_popup = _resolveComponent("van-popup");
      const _component_van_text_ellipsis = _resolveComponent("van-text-ellipsis");
      const _component_van_image = _resolveComponent("van-image");
      const _component_van_tag = _resolveComponent("van-tag");
      const _component_van_count_down = _resolveComponent("van-count-down");
      const _component_van_button = _resolveComponent("van-button");
      const _component_van_rate = _resolveComponent("van-rate");
      return _openBlock(), _createElementBlock(_Fragment, null, [[1].includes(__props.type) ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_van_swipe, {
        ref_key: "swiper",
        ref: swiper,
        class: "my-swipe",
        autoplay: 3000,
        "indicator-color": "white",
        onChange: change
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(adsList.value, (item, index) => {
          return _openBlock(), _createBlock(_component_van_swipe_item, {
            key: index
          }, {
            default: _withCtx(() => [_createElementVNode("img", {
              src: item.icon,
              alt: "",
              onClick: $event => clickImg(item)
            }, null, 8, _hoisted_2)]),
            _: 2
          }, 1024);
        }), 128))]),
        _: 1
      }, 512)])) : _createCommentVNode("", true), [3].includes(__props.type) ? (_openBlock(), _createElementBlock(_Fragment, {
        key: 1
      }, [adsList.value.length ? (_openBlock(), _createBlock(_component_van_swipe, {
        key: 0,
        class: "my-swipe3",
        autoplay: 3000,
        "indicator-color": "white"
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(adsList.value, (item, index) => {
          return _openBlock(), _createBlock(_component_van_swipe_item, {
            key: index
          }, {
            default: _withCtx(() => [_createElementVNode("img", {
              src: item.icon,
              alt: "",
              onClick: $event => clickImg(item)
            }, null, 8, _hoisted_3)]),
            _: 2
          }, 1024);
        }), 128))]),
        _: 1
      })) : _createCommentVNode("", true)], 64)) : _createCommentVNode("", true), __props.type === 4 ? (_openBlock(), _createElementBlock("div", _hoisted_4, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(adsList.value, (item, index) => {
        return _openBlock(), _createElementBlock("div", {
          key: index
        }, [_createElementVNode("img", {
          src: item.icon,
          alt: "",
          onClick: $event => clickImg(item)
        }, null, 8, _hoisted_5)]);
      }), 128))])) : _createCommentVNode("", true), _createVNode(_component_van_popup, {
        show: popAd.value.show,
        "onUpdate:show": _cache[0] || (_cache[0] = $event => popAd.value.show = $event),
        name: "bounce",
        duration: "0.5",
        "z-index": "2000",
        "close-on-click-overlay": false,
        class: "pop-ad",
        style: {
          width: '80%',
          background: 'none'
        }
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_6, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(adsList.value, (item, index) => {
          return _openBlock(), _createBlock(_Transition, {
            key: index,
            name: "animate__animated animate__zoomOut",
            "enter-active-class": "animate__zoomIn",
            "leave-active-class": "animate__zoomOut"
          }, {
            default: _withCtx(() => [_withDirectives(_createElementVNode("div", _hoisted_7, [_createElementVNode("div", _hoisted_8, [_createElementVNode("div", {
              class: "close-icon-div",
              onClick: $event => popAd.value.close(index)
            }, [_createVNode(_component_van_icon, {
              class: "close-icon",
              name: "cross"
            })], 8, _hoisted_9), _createElementVNode("img", {
              src: item.icon,
              alt: "",
              onClick: $event => clickImg(item)
            }, null, 8, _hoisted_10)])], 512), [[_vShow, popAd.value.showIndex === index]])]),
            _: 2
          }, 1024);
        }), 128))])]),
        _: 1
      }, 8, ["show"]), __props.type === 6 && adsList.value.length > 0 ? (_openBlock(), _createElementBlock("div", _hoisted_11, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(adsList.value, (item, index) => {
        return _openBlock(), _createElementBlock("div", {
          key: index
        }, [_createElementVNode("div", _hoisted_12, [_createElementVNode("img", {
          src: item.icon,
          alt: "",
          onClick: $event => clickImg(item)
        }, null, 8, _hoisted_13)]), _createElementVNode("div", _hoisted_14, [_createVNode(_component_van_text_ellipsis, {
          content: item.name
        }, null, 8, ["content"])])]);
      }), 128))])) : _createCommentVNode("", true), __props.type === 9 && adsList.value.length > 0 ? (_openBlock(), _createElementBlock("div", _hoisted_15, [_createElementVNode("div", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(adsList.value, (item, index) => {
        return _openBlock(), _createElementBlock("div", {
          key: index,
          onClick: $event => clickImg(item)
        }, _toDisplayString(item.name), 9, _hoisted_16);
      }), 128))])])) : _createCommentVNode("", true), __props.type === 10 && __props.list.length > 0 ? (_openBlock(true), _createElementBlock(_Fragment, {
        key: 5
      }, _renderList(__props.list, (item, index) => {
        return _openBlock(), _createElementBlock("div", {
          class: "list10",
          key: index
        }, [_createElementVNode("div", _hoisted_17, [_createVNode(_component_van_image, {
          class: "img",
          fit: "cover",
          src: item.icon,
          onClick: $event => clickImg(item)
        }, null, 8, ["src", "onClick"])]), _createElementVNode("div", _hoisted_18, _toDisplayString(item.name), 1)]);
      }), 128)) : _createCommentVNode("", true), __props.type === 11 ? (_openBlock(), _createElementBlock("div", _hoisted_19, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(adsList.value, (item, index) => {
        return _openBlock(), _createBlock(_component_van_tag, {
          key: index,
          type: "primary",
          color: "#cce5ff",
          "text-color": "#004085",
          onClick: $event => clickImg(item)
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(item.name), 1)]),
          _: 2
        }, 1032, ["onClick"]);
      }), 128))])) : _createCommentVNode("", true), __props.type === 12 ? (_openBlock(), _createElementBlock("div", _hoisted_20, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(adsList.value, (item, index) => {
        return _openBlock(), _createBlock(_component_van_tag, {
          key: index,
          type: "primary",
          color: "#222",
          "text-color": "#c6c6c6",
          onClick: $event => clickImg(item)
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(item.name), 1)]),
          _: 2
        }, 1032, ["onClick"]);
      }), 128))])) : _createCommentVNode("", true), __props.type === 13 ? (_openBlock(), _createElementBlock("div", _hoisted_21, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(adsList.value, (item, index) => {
        return _openBlock(), _createBlock(_component_van_tag, {
          key: index,
          type: "primary",
          color: "#222",
          "text-color": "#c6c6c6",
          onClick: $event => clickImg(item)
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(item.name), 1)]),
          _: 2
        }, 1032, ["onClick"]);
      }), 128))])) : _createCommentVNode("", true), __props.type === 14 && adsList.value.length ? (_openBlock(), _createElementBlock(_Fragment, {
        key: 9
      }, [show14.value ? (_openBlock(), _createElementBlock("div", _hoisted_22, [_createVNode(_component_van_swipe, {
        class: "my-swipe14",
        autoplay: 3000,
        "indicator-color": "white"
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(adsList.value, (item, index) => {
          return _openBlock(), _createBlock(_component_van_swipe_item, {
            key: index
          }, {
            default: _withCtx(() => [_createElementVNode("img", {
              src: item.icon,
              alt: "",
              onClick: $event => clickImg(item)
            }, null, 8, _hoisted_23), _createElementVNode("div", {
              class: "popup-close-icon-div",
              onClick: _cache[1] || (_cache[1] = $event => show14.value = false)
            }, [_createVNode(_component_van_icon, {
              name: "cross",
              class: "popup-close-icon"
            })])]),
            _: 2
          }, 1024);
        }), 128))]),
        _: 1
      })])) : _createCommentVNode("", true)], 64)) : _createCommentVNode("", true), __props.type === 15 ? (_openBlock(), _createElementBlock(_Fragment, {
        key: 10
      }, [adsList.value.length ? (_openBlock(), _createElementBlock("div", _hoisted_24, [_createElementVNode("div", _hoisted_25, [_createElementVNode("span", null, [_createVNode(_component_van_count_down, {
        time: seconds.value,
        format: "ss秒",
        onFinish: _cache[2] || (_cache[2] = $event => _ctx.$emit('close'))
      }, null, 8, ["time"])]), _createElementVNode("span", {
        onClick: _cache[3] || (_cache[3] = $event => _ctx.$emit('close'))
      }, "跳过")]), adsList.value.length ? (_openBlock(), _createBlock(_component_van_swipe, {
        key: 0,
        class: "my-swipe15",
        autoplay: 3000,
        "indicator-color": "white"
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(adsList.value, (item, index) => {
          return _openBlock(), _createBlock(_component_van_swipe_item, {
            key: index
          }, {
            default: _withCtx(() => [_createElementVNode("img", {
              src: item.icon,
              alt: "",
              onClick: $event => clickImg(item)
            }, null, 8, _hoisted_26), _createVNode(_component_van_icon, {
              name: "cross",
              class: "popup-close-icon"
            })]),
            _: 2
          }, 1024);
        }), 128))]),
        _: 1
      })) : _createCommentVNode("", true)])) : _createCommentVNode("", true)], 64)) : _createCommentVNode("", true), [16, 17, 18, 19].includes(__props.type) ? (_openBlock(), _createElementBlock("div", _hoisted_27, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(adsList.value, (item, index) => {
        return _openBlock(), _createElementBlock("div", {
          class: "item",
          key: index
        }, [_createElementVNode("div", null, [_createVNode(_component_van_image, {
          src: item.icon,
          fit: "cover"
        }, null, 8, ["src"])]), _createElementVNode("div", _hoisted_28, _toDisplayString(item.name), 1), _createElementVNode("div", _hoisted_29, _toDisplayString(item.remark || item.name), 1), _createVNode(_component_van_button, {
          type: "primary",
          color: "#ff9002",
          onClick: $event => clickImg(item)
        }, {
          default: _withCtx(() => [_createTextVNode("立即下载")]),
          _: 2
        }, 1032, ["onClick"])]);
      }), 128))])) : _createCommentVNode("", true), [20, 22].includes(__props.type) ? (_openBlock(), _createElementBlock("div", _hoisted_30, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(adsList.value, (item, index) => {
        return _openBlock(), _createElementBlock("div", {
          class: "item",
          key: index
        }, [_createVNode(_component_van_image, {
          src: item.icon,
          fit: "cover",
          onClick: $event => clickImg(item)
        }, null, 8, ["src", "onClick"])]);
      }), 128))])) : _createCommentVNode("", true), __props.type === 21 ? (_openBlock(), _createElementBlock("div", _hoisted_31, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(adsList.value, (item, index) => {
        return _openBlock(), _createElementBlock("div", {
          class: "game-item",
          key: index
        }, [_createElementVNode("div", null, [_createVNode(_component_van_image, {
          src: item.icon,
          fit: "cover"
        }, null, 8, ["src"])]), _createElementVNode("div", _hoisted_32, [_createElementVNode("div", null, [_createElementVNode("div", _hoisted_33, _toDisplayString(item.name), 1), _createElementVNode("div", null, [_createVNode(_component_van_rate, {
          modelValue: value.value,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => value.value = $event),
          size: 12,
          color: "#ffd21e",
          "void-icon": "star",
          "void-color": "#eee"
        }, null, 8, ["modelValue"])]), _createElementVNode("div", _hoisted_34, _toDisplayString(item.remark || item.name), 1)])]), _createElementVNode("div", _hoisted_35, [_createElementVNode("img", {
          src: _imports_0,
          onClick: $event => clickImg(item)
        }, null, 8, _hoisted_36)])]);
      }), 128))])) : _createCommentVNode("", true)], 64);
    };
  }
};