import { openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-e830b2fe"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "video-js vjs-default-skin vjs-big-play-centered",
  id: "my-player"
};
import videojs from "video.js";
import "video.js/dist/video-js.css";
import { onBeforeUnmount, computed, toRaw, ref } from "vue";
export default {
  __name: 'VideoPlay',
  props: ["info"],
  setup(__props, {
    expose: __expose
  }) {
    const props = __props;
    const info = computed(() => props.info);
    const player = ref({});
    const openFullscreen = video => {
      if (video.requestFullscreen) {
        video.requestFullscreen();
      } else if (video.mozRequestFullScreen) {
        video.mozRequestFullScreen();
      } else if (video.webkitRequestFullscreen) {
        video.webkitRequestFullscreen();
      } else if (video.msRequestFullscreen) {
        video.msRequestFullscreen();
      }
    };

    // 初始化播放器
    const initPlayer = obj => {
      if (player.value.id_) {
        player.value.pause();
        player.value.reset();
        toRaw(player.value).src(obj.href);
        player.value.poster(obj.upload_thumb);
        player.value.load();
        return;
      }
      player.value = videojs("my-player", {
        poster: obj.upload_thumb,
        width: "100%",
        controls: true,
        // 显示播放的控件
        // fluid: true, // 自适应宽高
        // playsinline: true,
        // webkitPlaysinline: true,
        fullscreen: true,
        // 导致视频一结束就重新开始。
        loop: false,
        muted: false,
        preload: "auto",
        sources: [
        // 注意，如果是以option方式设置的src,是不能实现 换台的 (即使监听了nowPlayVideoUrl也没实现)
        {
          src: obj.href
        }
        // 视频宽度,获取客户端宽度
        ]
      }, function onPlayerReady() {
        // videojs.log("播放器准备好了!");
        // In this context, `this` is the player that was created by Video.js.
        this.on('durationchange', function (e) {
          window.console.log('videoJS: durationchange');
        });
        this.on('ended', function (e) {
          window.console.log('videoJS: ended');
        });
        this.on('error', function (e) {
          window.console.log('videoJS: error');
        });
        this.on('firstplay', function (e) {
          window.console.log('videoJS: firstplay');
        });
        this.on('loadedalldata', function (e) {
          window.console.log('videoJS: loadedalldata');
        });
        this.on('loadeddata', function (e) {
          window.console.log('videoJS: loadeddata');
        });
        this.on('loadedmetadata', function (e) {
          window.console.log('videoJS: loadedmetadata');
        });
        this.on('loadstart', function (e) {
          window.console.log('videoJS: loadstart');
        });
        this.on('pause', function (e) {
          window.console.log('videoJS: pause');
        });
        this.on('play', function (e) {
          window.console.log('videoJS: play');
          openFullscreen(document.getElementById("my-player"));
        });
        this.on('seeked', function (e) {
          window.console.log('videoJS: seeked');
        });
        this.on('seeking', function (e) {
          window.console.log('videoJS: seeking');
        });
        this.on('waiting', function (e) {
          window.console.log('videoJS: waiting');
        });
        this.on('contentplayback', function (e) {
          window.console.log('videoJS: contentplayback');
        });
        this.on('ended', function (e) {
          window.console.log('videoJS: ended');
        });
        this.on('ima3error', function (e) {
          window.console.log('videoJS/IMA3: ima3error');
        });
        this.on('ima3-ad-error', function (e) {
          window.console.log('videoJS/IMA3: ima3-ad-error');
        });
        this.on('ima3-ready', function (e) {
          window.console.log('videoJS/IMA3: ima3-ready');
        });
        this.on('ads-request', function (e) {
          window.console.log('videoJS/Ads: ads-request');
        });
        this.on('ads-load', function (e) {
          window.console.log('videoJS/Ads: ads-load');
        });
        this.on('ads-ad-started', function (e) {
          window.console.log('videoJS/Ads: ads-ad-started');
        });
        this.on('ads-ad-ended', function (e) {
          window.console.log('videoJS/Ads: ads-ad-ended');
        });
        this.on('ads-first-quartile', function (e) {
          window.console.log('videoJS/Ads: ads-first-quartile');
        });
      });
    };
    const disposePlay = () => {
      if (player.value.dispose) {
        player.value.dispose();
      }
    };
    __expose({
      initPlayer
    });
    onBeforeUnmount(() => {
      disposePlay();
    });
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("video", _hoisted_1);
    };
  }
};