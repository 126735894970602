import "core-js/modules/es.array.push.js";
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-56397a14"), n = n(), _popScopeId(), n);
const _hoisted_1 = ["onClick"];
const _hoisted_2 = {
  class: "img-div"
};
const _hoisted_3 = {
  class: "mask"
};
const _hoisted_4 = {
  class: "title"
};
const _hoisted_5 = {
  class: "mask-div"
};
const _hoisted_6 = {
  class: "watch-times"
};
const _hoisted_7 = {
  class: "title"
};
import { computed, getCurrentInstance } from "vue";
import dayjs from 'dayjs';
export default {
  __name: 'VideoList',
  props: {
    list: {}
  },
  emits: ['query'],
  setup(__props) {
    const isDev = getCurrentInstance()?.appContext.config.globalProperties.$isDev;
    const formatDate = computed(() => {
      return date => {
        return dayjs(date * 1000).format('YYYY-MM-DD HH:mm:ss');
      };
    });
    return (_ctx, _cache) => {
      const _component_AdsPictures = _resolveComponent("AdsPictures");
      const _component_van_image = _resolveComponent("van-image");
      const _component_van_icon = _resolveComponent("van-icon");
      return _openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["video-list", 'onlytTopic'])
      }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.list, (item, index) => {
        return _openBlock(), _createElementBlock(_Fragment, {
          key: index
        }, [item.webIsAds ? (_openBlock(), _createBlock(_component_AdsPictures, {
          key: 0,
          list: [item],
          type: 10
        }, null, 8, ["list"])) : (_openBlock(), _createElementBlock("div", {
          key: 1,
          onClick: $event => item.href && _ctx.$router.push({
            path: '/details',
            query: {
              id: item.id,
              title: _ctx.$route.query.title
            }
          })
        }, [_createElementVNode("div", _hoisted_2, [item.icon ? (_openBlock(), _createBlock(_component_van_image, {
          key: 0,
          class: "img",
          fit: "cover",
          src: item.icon
        }, null, 8, ["src"])) : (_openBlock(), _createElementBlock(_Fragment, {
          key: 1
        }, [_createVNode(_component_van_image, {
          class: "img",
          fit: "cover",
          src: item.upload_thumb
        }, null, 8, ["src"]), _createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, _toDisplayString(_unref(isDev) ? "标题" : item.title), 1), _createElementVNode("div", null, [_createTextVNode("瓜农 · "), _createElementVNode("span", null, _toDisplayString(item.create_date), 1), _createTextVNode(" · 今日吃瓜，人人吃瓜")])]), _createElementVNode("div", _hoisted_5, [_createElementVNode("div", _hoisted_6, [_createVNode(_component_van_icon, {
          name: "eye-o"
        }), _createTextVNode(" " + _toDisplayString(item.watchtimes), 1)])])], 64))]), _createElementVNode("div", _hoisted_7, _toDisplayString(_unref(isDev) ? "标题" : item.title), 1)], 8, _hoisted_1))], 64);
      }), 128))]);
    };
  }
};