import { useStorage } from "@vueuse/core";
import { defineStore } from "pinia";
import api from "./api";
import { useRouter, useRoute } from "vue-router";
import dayjs from "dayjs";
import { ref } from "vue";

export const useIndexStore = defineStore("index", () => {
  const router = useRouter();
  const route = useRoute();
  const config = useStorage("config", {}, sessionStorage);
  const notice = useStorage("notice", [], sessionStorage);
  const categoryList = useStorage("categoryList", [], sessionStorage);
  const allAdsList = useStorage("allAdsList", [], sessionStorage);
  const allVideoList = useStorage("allVideoList", {}, sessionStorage);
  const recommendedList = useStorage("recommendedList", [], sessionStorage);
  const videoInfo = useStorage("videoInfo", {}, sessionStorage);
  const currentListParams = useStorage("currentListParams", {}, sessionStorage);
  const keys = ref("");
  const searchLog = useStorage("searchLog", [], sessionStorage);
  const currentVideoList = useStorage("currentVideoList", {}, sessionStorage);
  const currentVideoListParams = useStorage(
    "currentVideoListParams",
    {},
    sessionStorage
  );

  const setCurrentVideoList = (value) => {
    currentVideoList.value = value;
  };

  const setCurrentVideoListParams = (value) => {
    currentVideoListParams.value = value;
  };

  const setKeys = (value) => {
    keys.value = value;
  };

  const setSearchLog = (value) => {
    searchLog.value.push(value);
  };

  const delSearchLog = (value) => {
    const index = searchLog.value.findIndex((v) => v === value);
    if (index !== -1) {
      searchLog.value.splice(index, 1);
    }
  };

  const clearSearchLog = () => {
    searchLog.value = [];
  };

  const fetchConfig = async () => {
    try {
      const res = await api.queryWebsiteConfiguration();
      config.value = res.data;
      document.title = res.data.logo_title;
      let $favicon = document.querySelector('link[rel="icon"]');
      $favicon.href = res.data.favicon_ico;
    } catch (error) {
      Promise.reject(error);
    }
  };

  const fetchAnnouncement = async () => {
    try {
      const res = await api.queryAnnouncement();
      notice.value = res.data;
    } catch (error) {
      Promise.reject(error);
    }
  };

  const fetchAllAd = async () => {
    try {
      const res = await api.getAllAd();
      allAdsList.value = res.data;
      if (!res.data.filter((v) => v.class_id == 15).length) {
        // showAd.value = false;
      } else {
        // adList.value = res.data
        // sessionStorage.setItem('startAd', 1)
      }
    } catch (error) {
      Promise.reject(error);
    }
  };

  // 查询分类列表
  const fetchClassificationList = async () => {
    try {
      const res = await api.queryClassificationList();
      res.data = res.data.filter((v) => v.name !== "首页");
      categoryList.value = res.data;
    } catch (error) {
      Promise.reject(error);
    }
  };

  // 查询首页视频列表
  const fetchHomepageVideoList = async (params) => {
    try {
      const res = await api.queryHomeVideo();
      res.data.map((v) => {
        if (v.list.length > 6) {
          v.list = v.list.slice(0, 6);
        }
      });
      if (params?.last) {
        recentList.value = res.data.find((v) =>
          ["最近更新"].includes(v.title)
        ).list;
      } else {
        let newList = res.data;
        [newList[0], newList[1]] = [newList[1], newList[0]];
        allVideoList.value.home = newList
          .filter((v) => !["黑料"].includes(v.title))
          .filter((v) => v.list.length);
      }
    } catch (error) {
      Promise.reject(error);
    }
  };

  // 根据分类查询视频列表
  const fetchVideoList = async (payload) => {
    try {
      const obj = {
        id: 0,
        page: 1,
        size: 10,
        ...payload,
      };
      const res = await api.queryVideoClassificationList(obj);
      currentListParams.value = obj;
      allVideoList.value = res.data;
      res.data.list.forEach((v) => {
        v.create_date = dayjs(v.create_date).format("YYYY-MM-DD HH:mm:ss");
      });
      return res;
    } catch (error) {
      Promise.reject(error);
    } finally {
    }
  };

  // 查询推荐列表
  const fetchRecommendedList = async () => {
    const res = await api.queryRecommendedList({
      id: route.query.id,
    });

    const adsList = await api.queryAd({
      type: 10,
    });

    if (res.data && res.data.length) {
      // adsList.data.map((v) => {
      //   v.webIsAds = true;
      //   res.data.splice(2, 0, v);
      // });
      recommendedList.value = res.data;
      // recommendedList.value = [
      //   res.data[Math.floor(Math.random() * res.data.length)],
      // ];
    }
  };

  const fetchDetails = async () => {
    const res = await api.queryVideoDetails({
      id: route.query.id,
    });

    res.data.create_date = dayjs(res.data.create_date).format("YYYY年MM月DD日");
    videoInfo.value = res.data;
  };

  const fetchReportInstall = async () => {
    try {
      await api.reportInstall();
    } catch (error) {
      Promise.reject(error);
    }
  };

  return {
    config,
    allAdsList,
    categoryList,
    notice,
    allVideoList,
    recommendedList,
    videoInfo,
    currentListParams,
    keys,
    searchLog,
    currentVideoList,
    currentVideoListParams,
    setCurrentVideoListParams,
    setCurrentVideoList,
    setKeys,
    setSearchLog,
    delSearchLog,
    clearSearchLog,
    fetchConfig,
    fetchAnnouncement,
    fetchClassificationList,
    fetchAllAd,
    fetchHomepageVideoList,
    fetchVideoList,
    fetchRecommendedList,
    fetchDetails,
    fetchReportInstall,
  };
});
