import ads from "@/api/modules/ads";

/**
 * @description: 加载脚本
 * @param {*} attributes 属性
 * @param {*} callback 回调函数
 */
export function seriesLoadScripts(attributes, callback) {
  const virtualAnimationScript = document.createElement("script");
  for (let key in attributes) {
    virtualAnimationScript[key] = attributes[key];
  }
  virtualAnimationScript.onload = virtualAnimationScript.onreadystatechange =
    () => {
      try {
        callback();
      } catch (error) {
        throw new Error(error);
      }
    };
  document.body.appendChild(virtualAnimationScript);
}

// 是否移动端
export function isMobile() {
  let flag = navigator.userAgent.match(
    /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
  );
  return flag;
}

// 活到顶部
export const scrollToTop = () => {
  let sTop = document.documentElement.scrollTop || document.body.scrollTop;
  if (sTop > 0) {
    window.requestAnimationFrame(scrollToTop);
    window.scrollTo(0, sTop - sTop / 8);
  }
};

/*判断客户端*/
export function judgeClient() {
  let u = navigator.userAgent;
  let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1; //判断是否是 android终端
  let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //判断是否是 iOS终端

  if (isAndroid) {
    return "Android";
  } else if (isIOS) {
    return "IOS";
  } else {
    return "PC";
  }
}

export function getUrlParam(name, url) {
  let u = arguments[1] || window.location.href,
    reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)"),
    r = u.substr(u.indexOf("?") + 1).match(reg);
  return r != null ? decodeURI(r[2]) : "";
}

export function goToTheRandomAddress(arr) {
  let url = arr[Math.floor(Math.random() * arr.length)];

  if (!url) {
    alert("获取配置失败，请联系管理员！");
    return;
  }

  if (!url.includes("http")) {
    url = "https://" + url;
  }

  window.open(url);
}

export const downloadApp = (iosArr, androidArr) => {
  if (judgeClient() === "IOS") {
    goToTheRandomAddress(iosArr);
  }

  if (judgeClient() === "Android") {
    goToTheRandomAddress(androidArr);
  }
};

export function isH5() {
  let _flag = true;

  if (
    window.matchMedia("(display-mode: standalone)").matches ||
    window.navigator?.standalone ||
    document.referrer.includes("android-app://")
  ) {
    _flag = false;
  }

  return _flag;
}

// 是否为ios webClip 描述文件
export function isWebClip() {
  return window.navigator.standalone === true;
}

export function getAdsId(ids, list) {
  let adsId = [];
  list
    .filter((v) => ids.split(",").includes(v.class_id + ""))
    .forEach((v) => {
      adsId.push(v.id);
    });
  return adsId.join();
}

export function isDev() {
  return process.env.NODE_ENV === "development";
}
