import { createRouter, createWebHistory } from "vue-router";

export const routes = [
  {
    path: "/",
    redirect: "/home",
    component: () => import("./layout/index.vue"),
    children: [
      {
        path: "/home",
        component: () => import("./views/home"),
        meta: {
          title: "首页",
          keepAlive: true,
        },
      },
      {
        path: "/welfare",
        component: () => import("./views/welfare"),
        meta: {
          title: "福利",
          keepAlive: true,
        },
      },
      {
        path: "/game",
        component: () => import("./views/game"),
        meta: {
          title: "游戏",
          keepAlive: true,
        },
      },
      {
        path: "/details",
        component: () => import("./views/details"),
        meta: {
          title: "详情",
          keepAlive: true,
        },
      },
      {
        path: "/search",
        component: () => import("./views/search"),
        meta: {
          title: "搜索",
          keepAlive: false,
        },
      },
    ],
  },
  // 匹配不到重定向会主页
  {
    path: "/:pathMatch(.*)",
    redirect: "/",
  },
];

export default createRouter({
  history: createWebHistory(),
  scrollBehavior() {
    // 始终滚动到顶部
    return { top: 0 };
  },
  routes,
});
