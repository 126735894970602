import { createApp } from "vue";
import App from "./App.vue";
import router from "./route";
import install from "./install";
import { createPinia } from "pinia";
import "animate.css/animate.min.css";

const pinia = createPinia();
const app = createApp(App);

app.use(install);
app.use(router);
app.use(pinia);
app.mount("#app");
